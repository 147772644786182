.risk-profile-container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    padding: 20px;    
}

.risk-profile-subcontainer{
    padding: 35px;
    text-align: start;
    border-radius: 15px;
    color: #333333;
    border: 2px solid #fff;
    width: 100%;
    box-shadow: 0 0.5rem 1rem rgb(0 0 0 / 15%);
    -webkit-transition: all 500ms ease-in-out;
    -moz-transition: all 500ms ease-in-out;
    -ms-transition: all 500ms ease-in-out;
    -o-transition: all 500ms ease-in-out;
    transition: all 500ms ease-in-out;
    margin-bottom: 50px;

}

.risk-profile-subcontainer:hover{
    border: 2px solid #04638b;
    box-shadow: 0 0.5rem 1rem rgb(0 0 0 / 15%);
}


@media screen and (min-width: 992px) {
    .risk-profile-subcontainer{
        width: 80%;
    }
  }

.radio-custom-label{
    padding-left: 20px;
}
.radio-custom:checked ~ label {
    color: #04638b;
  }
  
  label {
    /* display: block; */
    flex: 1;
  }
  
  .input-group {
    /* display: block; */
  }
  
  .text-group-field {
    padding: 10px;
  }
  
  .inner-block {
    position: relative;
    padding: 10px;
    margin: 10px;
    display: flex;
}


input[type='radio']:checked:after {
    width: 15px;
    height: 15px;
    border-radius: 15px;
    top: -5px;
    left: -1px;
    position: relative;
    background-color: #04638b;
    content: '';
    display: inline-block;
    visibility: visible;
    border: 2px solid white;
}

html{
  scroll-behavior: smooth;
}