.cloudservicetabitem-investor{
    padding:0px;
}


/* On screens that are 992px or less, set the background color to blue */
@media screen and (min-width: 992px) {
    .cloudservicetabitem-investor{
        margin-bottom:600px;

    }
  }